@charset "UTF-8";

* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

html,
body {
  height: 100vh;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.8;
  color: #333;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Medium.woff') format('woff'), url('./fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Bold.woff') format('woff'), url('./fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

#root {
  height: 100vh;
  position: relative;
}

/* ----------------------------------
   Bootstrap Fix: IE10
   in Windows 8 & Windows Phone 8
   ---------------------------------- */

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

// Typography

h1 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 10px;
}

h2 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin-top: 0;
  margin-bottom: 30px;
}

h3 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}

strong,
b {
  font-weight: 700;
}
