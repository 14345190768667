.coming-soon {
  height: 100%;
  background-color: #333;

  /* ___________________________________________

                   CONTENT
   ___________________________________________ */

  /*   01. General Styles                        */
  /*   02. Typography                            */
  /*   03. Backgrounds                           */
  /*   04. Buttons, Links                        */
  /*   05. Forms                                 */
  /*   06. Spacing, Alignment, Helperclasses     */
  /*   07. Navigation                            */
  /*   08. Backgrounds                           */
  /*   09. Preloader                             */
  /*   10. Modal                                 */
  /*   11. Misc                                  */

  /* _____________________________________

   01. General Styles
   _____________________________________ */

  /* _____________________________________

   02. Typography
   _____________________________________ */

  .font-weight-200 {
    font-weight: 200;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .wrap-line {
    margin: 20px 0 50px 0;
    position: relative;
  }

  .wrap-line:before {
    position: absolute;
    width: 40px;
    height: 1px;
    bottom: -30px;
    left: 50%;
    margin-left: -20px;
    content: '';
    border-bottom: 1px solid;
  }

  /* ----------------------------------
   Icons
   ---------------------------------- */

  .icon {
    line-height: 1;
  }

  .icon:before {
    vertical-align: bottom;
  }

  .icon-sm {
    font-size: 24px;
  }

  .icon-lg {
    font-size: 34px;
  }

  /* ----------------------------------
   Font Colors
   ---------------------------------- */

  .text-color {
    color: #00acc1;
  }

  .text-white {
    color: #fff;
  }

  .text-light {
    color: #aaa;
  }

  .text-grey {
    color: #444;
  }

  /* _____________________________________

   03. Backgrounds
   _____________________________________ */

  /* _____________________________________

   04. Buttons, Links
   _____________________________________ */

  a {
    color: #333;
    text-decoration: none;
  }

  a > i {
    margin: 0 7px;
  }

  a:hover,
  a:active,
  a:focus {
    color: #000;
    text-decoration: underline;
    outline: none;
  }

  a.link-white,
  a.link-white:hover,
  a.link-white:active,
  a.link-white:focus {
    color: #fff;
  }

  a.link-light,
  a.link-light:hover,
  a.link-light:active,
  a.link-light:focus {
    color: #fff;
  }

  .btn-row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .btn,
  a.btn {
    display: inline-block;
    margin: 0 5px;
    padding: 14px;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 0;
    transition: background 0.3s ease-in-out;
  }

  .btn i {
    margin-right: 15px;
  }

  .btn.active.focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn:active:focus,
  .btn:focus {
    outline: none;
  }

  .input-group-btn:last-child > .btn {
    margin: 0 !important;
    padding: 12px 13px 13px 13px;
    line-height: 1.392;
    border: none;
  }

  .input-group-btn:last-child > .btn i,
  .input-group-btn:last-child > .btn-group i {
    margin: 0 !important;
  }

  /* ----------------------------------
   Button Dark
   ---------------------------------- */

  .btn.btn-dark,
  a.btn.btn-dark {
    color: #fff;
    background-color: #333;
  }

  .btn.btn-dark:hover,
  a.btn.btn-dark:hover,
  .btn.btn-dark:active,
  a.btn.btn-dark:active,
  .btn.btn-dark:focus,
  a.btn.btn-dark:focus {
    color: #fff;
    background-color: #000;
    box-shadow: none;
  }

  /* ----------------------------------
   Button Color
   ---------------------------------- */

  .btn-color,
  a.btn-color {
    color: #fff;
    background-color: #00acc1;
  }

  .btn.btn-color:hover,
  .btn.btn-color:active,
  .btn.btn-color:focus,
  a.btn.btn-color:hover,
  a.btn.btn-color:active,
  a.btn.btn-color:focus {
    color: #fff;
    background-color: #0097a7;
  }

  /* ----------------------------------
   Button White
   ---------------------------------- */

  .btn-white,
  a.btn-white {
    color: #333;
    background-color: #fff;
  }

  .btn.btn-white:hover,
  .btn.btn-white:active,
  .btn.btn-white:focus,
  a.btn.btn-white:hover,
  a.btn.btn-white:active,
  a.btn.btn-white:focus {
    color: #333;
    background-color: #fff;
  }

  /* ----------------------------------
   Button Border White
   ---------------------------------- */

  .btn.btn-border-white,
  a.btn.btn-border-white {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
  }

  .btn.btn-border-white:hover,
  .btn.btn-border-white:active,
  .btn.btn-border-white:focus,
  a.btn.btn-border-white:hover,
  a.btn.btn-border-white:active,
  a.btn.btn-border-white:focus {
    color: #333;
    background-color: #fff;
    border: 1px solid #fff;
  }

  /* _____________________________________

   05. Forms
   _____________________________________ */

  .form-control {
    height: 50px;
    padding: 15px 0 13px 0;
    margin: 0 auto;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    background-color: transparent;
    color: #333;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    appearance: none;
    outline: 0;
  }

  .form-control-custom {
    border: 0;
    height: 2px;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-control:focus {
    border-color: #333;
    box-shadow: none;
    outline: none;
  }

  textarea.form-control {
    overflow: hidden;
    display: block;
    resize: none;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .form-group,
  .input-group {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    transition: width 0.5s ease-in-out;
  }

  .focus.form-group,
  .focus.input-group {
    width: 100%;
  }

  input:focus::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  /* ----------------------------------
   Input Light
   ---------------------------------- */

  .form-control.form-control-light {
    color: #aaa;
    border-color: #aaa;
  }

  .form-control.form-control-light:-moz-placeholder {
    color: #aaa;
  }

  .form-control.form-control-light::-moz-placeholder {
    color: #aaa;
  }

  .form-control.form-control-light:-ms-input-placeholder {
    color: #aaa;
  }

  .form-control.form-control-light::-webkit-input-placeholder {
    color: #aaa;
  }

  /* ----------------------------------
   Input Dark
   ---------------------------------- */

  .form-control.form-control-dark {
    color: #333;
    border-color: #333;
  }

  .form-control.form-control-dark:-moz-placeholder {
    color: #333;
  }

  .form-control.form-control-dark::-moz-placeholder {
    color: #333;
  }

  .form-control.form-control-dark:-ms-input-placeholder {
    color: #333;
  }

  .form-control.form-control-dark::-webkit-input-placeholder {
    color: #333;
  }

  /* ----------------------------------
   Alert Placeholder
   ---------------------------------- */

  .error .form-control:-moz-placeholder {
    color: #c0392b;
  }

  .error .form-control::-moz-placeholder {
    color: #c0392b;
  }

  .error .form-control:-ms-input-placeholder {
    color: #c0392b;
  }

  .error .form-control::-webkit-input-placeholder {
    color: #c0392b;
  }

  .error .form-control {
    color: #c0392b;
  }

  /* ----------------------------------
   Validation Message
   ---------------------------------- */

  .message-wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1030;
  }

  .map + .message-wrapper {
    bottom: 400px;
  }

  .message-wrapper.fixed {
    position: fixed;
  }

  .message {
    padding: 15px;
    background-color: #00acc1;
  }

  .error.message {
    background-color: #c0392b;
  }

  label.message-text {
    margin-bottom: 0;

    a {
      display: block;
    }
  }

  .message-text {
    margin-left: 15px;
    font-weight: 400;
  }

  /* _____________________________________

   06. Spacing, Alignment, Helperclasses
   _____________________________________ */

  .vert-middle {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .vert-middle > div {
    width: 100%;
    vertical-align: middle;
  }

  .p-t-b-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .p-t-b-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .p-t-b-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .p-t-b-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .p-t-b-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .p-t-b-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .arrow-wrapper {
    padding: 30px;
  }

  /* _____________________________________

   07. Navigation
   _____________________________________ */

  .navbar-wrapper {
    height: 60px;
    position: relative;
  }

  /* ----------------------------------
   Navbar Links
   ---------------------------------- */

  .nav > li > a:focus,
  .nav > li > a:hover {
    background-color: transparent;
  }

  .navbar-links {
    float: right;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .navbar-links ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .navbar-links li {
    float: left;
    opacity: 0;
    transform: translate(100%, 0);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .navbar-links .nav > li > a {
    display: inline;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .navbar-links .nav > li {
    margin: 20px 15px;
  }

  .navbar-links .nav > li > a:hover {
    background-color: transparent;
  }

  .navbar-links .nav > li.active > a {
    border-bottom: 1px solid #fff;
  }

  .full-info .navbar-links li {
    transform: translate(0px, 0px);
    opacity: 1;
    visibility: visible;
  }

  /* ----------------------------------
   Navbar Button
   ---------------------------------- */

  header {
    display: none;
  }

  .navbar-button {
    position: relative;
    float: right;
    width: 25px;
    height: 19px;
    margin: 22px 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .navbar-button span {
    position: absolute;
    left: 0;
    display: block;
    height: 1px;
    width: 100%;
    background-color: #fff;
    border-radius: 9px;
    filter: alpha(opacity=100);
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .navbar-button span:nth-child(1) {
    top: 0;
  }

  .navbar-button span:nth-child(2) {
    top: 7px;
  }

  .navbar-button span:nth-child(3) {
    top: 14px;
  }

  .navbar-open .navbar-button span:nth-child(1) {
    top: 7px;
    transform: rotate(135deg);
  }

  .navbar-open .navbar-button span:nth-child(2) {
    left: -40px;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  .navbar-open .navbar-button span:nth-child(3) {
    top: 7px;
    transform: rotate(-135deg);
  }

  /* _____________________________________

   08. Backgrounds
   _____________________________________ */

  .section-overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #1f64c5;
  }

  .section-overlay img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  .overlay-opacity {
    filter: alpha(opacity=50);
    opacity: 0.3;
  }

  .overlay-opacity-2 {
    filter: alpha(opacity=40);
    opacity: 0.4;
  }

  .overlay-opacity-3 {
    filter: alpha(opacity=60);
    opacity: 0.6;
  }

  /* _____________________________________

   09. Preloader
   _____________________________________ */

  .loader {
    position: fixed;
    z-index: 1200;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: opacity 1s linear;
    background-color: #333;
  }

  .fade-out.loader {
    opacity: 0;
  }

  .fade-out .loader-inner {
    display: none;
  }

  .ball-scale-ripple-multiple-color > div {
    border-color: #00acc1;
  }

  .ball-scale-ripple-multiple-bw > div {
    border-color: #fff;
  }

  .ball-pulse-sync > div,
  .ball-pulse > div {
    width: 10px;
    height: 10px;
    margin: 5px;
  }

  /* _____________________________________

   10. Modal
   _____________________________________ */

  .button-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 0;
  }

  .button-control {
    float: left;
    margin: 15px 5px;
    padding: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    line-height: 1;
    border: 0;
    cursor: pointer;
    z-index: 100;
  }

  .modal.modal-scale.fade .modal-dialog {
    display: table;
    height: 100%;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1);
    transform-origin: center;
    margin: 0 auto;
    opacity: 0.1;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
  }

  .modal.modal-scale .modal-dialog > div {
    display: table-cell;
    vertical-align: middle;
  }

  .modal.modal-scale.fade.in .modal-dialog {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  .modal-content {
    padding: 50px 80px;
    border-radius: 0;
    border: 0;
    background-color: #333;

    .input-group-btn:last-child > .btn {
      border-bottom: 1px solid transparent;
    }
  }

  .modal-backdrop.in {
    filter: alpha(opacity=20);
    opacity: 0.2;
  }

  /* _____________________________________

   11. Misc
   _____________________________________ */

  .page-info {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    .logo {
      height: 172px;
      width: 140px;
    }

    .tagline {
      font-size: 14px;
      font-style: italic;
      margin-bottom: 20px;
      display: block;
      text-transform: none;
      letter-spacing: 0.2em;
    }

    .verbiage {
      font-size: 16px;
      max-width: 600px;
      text-align: center;
      margin: 0 auto;
    }
  }

  .page-content {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .sr .reveal {
    visibility: hidden;
  }

  .map > div {
    width: 100%;
    height: 400px;
  }

  .map h4,
  .map h5 {
    margin-top: 0;
  }

  @media only screen and (max-width: 767px) {
    .modal.modal-scale.fade {
      margin: 15px;
    }

    .modal.modal-scale.fade .modal-dialog {
      width: 100%;
    }

    .modal-content {
      padding: 50px 15px;
    }
  }

  @media only screen and (min-width: 991px) {
    body {
      overflow: hidden;
    }

    &.show-content {
      overflow: scroll;
    }

    header {
      display: block;
    }

    .page-info {
      padding-top: 60px;
      position: fixed;
    }

    .col-transform {
      transition: all 0.6s ease-in-out;
    }

    .page-content {
      position: absolute;
      visibility: hidden;
      right: -50%;
      transition: all 0.6s ease-in-out;
    }

    &.show-content .page-content {
      overflow: scroll;
      position: absolute;
      right: 0;
      visibility: visible;
    }
  }

  @media screen and (max-height: 667px) {
    .page-info {
      .logo {
        height: 86px;
        width: 70px;
      }

      .headline {
        font-size: 18px;
      }

      .tagline {
        font-size: 11px;
      }

      .verbiage {
        font-size: 14px;
      }
    }
  }

  @media screen and (min-height: 668px) and (max-height: 736px) {
    .page-info {
      .logo {
        height: 129px;
        width: 105px;
      }

      .tagline {
        font-size: 12px;
      }

      .headline {
        font-size: 22px;
      }
    }
  }
}
